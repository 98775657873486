//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {userLogin} from "@/api/login";

export default {
  name: 'UserLogin',
	data () {
		return {
			loginForm: {
				username: '',
				password: ''
			},
			rules: {
				username: [
					{ required: true, message: 'Please input username', trigger: 'blur' },
					{ min: 3, message: 'Length should be more than 3 letters', trigger: 'blur' }
				],
				password: [
					{ required: true, message: 'Please input password', trigger: 'change' }
				]
			},
			passwordType: 'password',
			errorMsg: ''
		}
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
	},
	methods: {
		showPwd() {
			if (this.passwordType === 'password') {
				this.passwordType = ''
			} else {
				this.passwordType = 'password'
			}
			this.$nextTick(() => {
				this.$refs.password.focus()
			})
		},
		login() {
			this.$refs.loginForm.validate(async (valid) => {
				let username = this.loginForm.username
				let password = this.loginForm.password
				if (valid) {
					this.$session.start()
					const params = new URLSearchParams()
					params.append('username', username.trim())
					params.append('password', password)
					await new Promise((resolve, reject) => {
						userLogin(params).then(response => {
							const check = response.header
							const data = response.body
							if (check.error === 'N') {
								this.$session.set("token", data.token)
								this.$session.set("username", data.userinfo.username)
								this.$session.set("fullname", data.userinfo.username)
								this.$session.set("role", data.userinfo.role_id)
								resolve()
							}
						}).catch(error => {
							this.errorMsg = error
							reject(error)
						})
					})

					if (this.$session.get('token')) {
						this.$router.push('/user/contracts')
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		goBack() {
			this.$router.push({path: '/'})
		}
	}
}
